var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "11"
    }
  }, [_c('form-tanggal', {
    on: {
      "submit": _vm.submit
    }
  }), _vm.journals ? _c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_vm._v(" Download ")])], 1), _c('b-table', {
    attrs: {
      "small": "",
      "fields": _vm.fields,
      "items": _vm.journals,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(order)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(nomor)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.akun ? _c('span', [_vm._v(" " + _vm._s(item.akun.nomor) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v(" Nomor akun tidak ditemukan ")])];
      }
    }, {
      key: "cell(akun)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.akun ? _c('span', [_vm._v(" " + _vm._s(item.akun.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v(" Akun tidak ditemukan ")])];
      }
    }, {
      key: "cell(debit)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.debit)))])];
      }
    }, {
      key: "cell(kredit)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.kredit)))])];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.debit - item.kredit)))])];
      }
    }], null, false, 3724260333)
  })], 1) : _c('section', {
    staticClass: "alert alert-danger p-1"
  }, [_vm._v(" Harap pilih tanggal awal dan tanggal akhir untuk melihat hasil ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }